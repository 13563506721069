import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { io } from 'socket.io-client';
// custom hooks
import { useAppDispatch } from 'redux/hooks/useAppDispatch';
import { useAppSelector } from 'redux/hooks/useAppSelector';
// api
import {
  ISocketComment,
  ISocketPost,
  ISocketSelectionNotification,
  SelectionNotificationEventEnum,
  TActivityFeedEvent,
  TSocketNotification,
  activityFeedApi,
} from 'redux/api/activity-feed';
import { useGetMeQuery } from 'redux/api/auth';
import { IUnviewedItem, projectsApi } from 'redux/api/projects';
import { SelectionStatusEnum, selectionsApi } from 'redux/api/selections';
// slices
import {
  addComment,
  editComment,
  deleteComments as deleteLocalComment,
  likeUnlikeComment as likeUnlikeLocalComment,
} from 'redux/slices/new-comments-slice';
// selectors
import { authSelector, queryArgsSelector } from 'redux/selector';
// helpers
import { httpToWs } from 'utils/httpToWs';
import { getTotalSelectionsAmount } from 'redux/api/selections/helpers';
import { handlePostReplyDefault } from 'redux/slices/reply-to-post-slice';

const socketUrl = `${httpToWs(process.env.REACT_APP_API_URL)}activity-feed`;

enum SocketEvents {
  USER_JOINED_EVENT = 'user_joined',
  SEND_MESSAGE_EVENT = 'send_message',
  ERROR_EVENT = 'user_error',
  DISCONNECT_EVENT = 'user_disconnect',
  ADD_TO_PROJECT_EVENT = 'USER_JOINING_TO_ROOM',
  JOIN_TO_ROOM_EVENT = 'join_to_room_event',
}

interface IJoinEvent {
  msg: string;
  rooms: string;
  socketId: string;
}

const isEventTypeSelection = (data: TSocketNotification): data is ISocketSelectionNotification => {
  return Object.values(SelectionNotificationEventEnum).some((event) => event === data.event);
};

export const useSocketListener = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { isAuth } = useAppSelector(authSelector);
  const { projects: projectsArgs } = useAppSelector(queryArgsSelector);
  const isAccountDashboard = pathname.startsWith('/account-dashboard');
  const { data: userMe } = useGetMeQuery(undefined, { skip: !isAuth });
  const { data: projectsData } = projectsApi.endpoints.getProjects.useQueryState(projectsArgs, {
    skip: !isAccountDashboard,
  });
  const companyId = localStorage.getItem('company-id');
  const projectId =
    pathname.startsWith('/project-dashboard') || pathname.startsWith('/selections')
      ? Number(pathname.split('/')[2])
      : null;
  const projectIds = projectsData?.items?.map(({ id }) => id);
  const socket = useRef<ReturnType<typeof io> | null>(null);

  useEffect(() => {
    if (companyId) {
      socket.current = io(socketUrl, {
        extraHeaders: {
          Authorization: localStorage.getItem('Authorization') || '',
        },
        query: {
          companyId,
        },
      });
    }

    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.disconnect();
      }
    };
  }, [companyId]);

  useEffect(() => {
    const addNotification = (
      unviewedItemType: keyof IUnviewedItem,
      eventProjectId: number,
      cacheKey: number[],
      filterCondition: boolean = true
    ) => {
      if (filterCondition) {
        dispatch(
          projectsApi.util.updateQueryData(
            'getUnviewedCounts',
            { projectIds: cacheKey },
            (draft) => {
              if (typeof draft?.items?.[eventProjectId]?.[unviewedItemType] === 'number') {
                draft.items[eventProjectId][unviewedItemType] += 1;
              }
            }
          )
        );
      }
    };

    const addPost = (data: ISocketPost, eventProjectId: number) => {
      dispatch(
        activityFeedApi.util.updateQueryData('getPosts', { projectId: eventProjectId }, (draft) => {
          if (draft?.items) {
            draft.items = [
              {
                ...data,
                isViewed: userMe.id === data?.data?.profile?.id,
              },
              ...draft.items,
            ];
          }
        })
      );
    };

    const updatePost = (data: ISocketPost, eventProjectId: number) => {
      dispatch(
        activityFeedApi.util.updateQueryData('getPosts', { projectId: eventProjectId }, (draft) => {
          if (draft?.items) {
            const postIndex = draft.items.findIndex(({ id }) => id === data.id);
            if (postIndex >= 0) {
              draft.items[postIndex] = Object.assign(draft.items[postIndex], data);
            }
          }
        })
      );

      const postId = Number(searchParams.get('postId'));

      if (postId) {
        dispatch(
          activityFeedApi.util.updateQueryData(
            'getPostById',
            { projectId: eventProjectId, postId },
            (draft) => {
              if (draft) {
                draft = Object.assign(draft, data);
              }
            }
          )
        );
      }
    };

    const updateComment = (data: ISocketComment) => {
      dispatch(editComment(data));

      dispatch(
        activityFeedApi.util.updateQueryData('getComments', { postId: data.postId }, (draft) => {
          if (draft?.items) {
            if (data.rootCommentId) {
              const rootIndex = draft.items.findIndex(({ id }) => id === data.rootCommentId);
              if (rootIndex >= 0) {
                const commentIndex = draft.items[rootIndex]?.replies?.findIndex(
                  ({ id: replyId }) => replyId === data.id
                );
                if (commentIndex >= 0) {
                  draft.items[rootIndex].replies[commentIndex] = Object.assign(
                    draft.items[rootIndex].replies[commentIndex],
                    data
                  );
                }
              }
            } else {
              const commentIndex = draft.items.findIndex(({ id }) => data.id);
              if (commentIndex >= 0) {
                draft.items[commentIndex] = Object.assign(draft.items[commentIndex], data);
              }
            }
          }
        })
      );
    };

    const deletePost = (activityFeedItemId: number, eventProjectId: number) => {
      dispatch(
        activityFeedApi.util.updateQueryData('getPosts', { projectId: eventProjectId }, (draft) => {
          if (draft?.items) {
            draft.items = draft.items.filter(({ id }) => id !== activityFeedItemId);
          }
        })
      );

      if (activityFeedItemId === Number(searchParams.get('postId'))) {
        searchParams.delete('postId');
        searchParams.delete('projectId');
        setSearchParams(searchParams);
        dispatch(handlePostReplyDefault());
      }
    };

    const deleteComment = (
      activityFeedItemId: number,
      postId: number,
      eventProjectId?: number,
      rootCommentId?: number
    ) => {
      dispatch(
        deleteLocalComment({
          id: activityFeedItemId,
          postId,
          rootCommentId,
        })
      );

      dispatch(
        activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
          if (draft?.items) {
            if (rootCommentId) {
              const rootIndex = draft.items.findIndex(({ id }) => id === rootCommentId);
              if (rootIndex >= 0 && draft.items[rootIndex]?.replies?.length) {
                draft.items[rootIndex].replies = draft.items[rootIndex].replies.filter(
                  ({ id: commentId }) => commentId !== activityFeedItemId
                );
              }
            } else {
              draft.items = draft.items.filter(
                ({ id: commentId }) => commentId !== activityFeedItemId
              );
            }
          }
        })
      );

      dispatch(
        activityFeedApi.util.updateQueryData('getPosts', { projectId: eventProjectId }, (draft) => {
          if (draft?.items) {
            const postIndex = draft.items.findIndex(({ id }) => id === postId);
            if (postIndex >= 0 && draft.items[postIndex].commentsCount) {
              draft.items[postIndex].commentsCount -= 1;
            }
          }
        })
      );

      if (postId === Number(searchParams.get('postId'))) {
        dispatch(
          activityFeedApi.util.updateQueryData(
            'getPostById',
            { postId, projectId: eventProjectId },
            (draft) => {
              if (draft && draft.commentsCount) {
                draft.commentsCount -= 1;
              }
            }
          )
        );
      }
    };

    const likeUnlikePost = (
      activityFeedItemId: number,
      eventProjectId: number,
      actionType: 'like' | 'unlike'
    ) => {
      dispatch(
        activityFeedApi.util.updateQueryData('getPosts', { projectId: eventProjectId }, (draft) => {
          if (draft?.items) {
            const postIndex = draft.items.findIndex(({ id }) => id === activityFeedItemId);
            if (postIndex >= 0) {
              if (actionType === 'like') {
                draft.items[postIndex].likesCount += 1;
              } else {
                draft.items[postIndex].likesCount -= 1;
              }
            }
          }
        })
      );

      if (activityFeedItemId === Number(searchParams.get('postId'))) {
        dispatch(
          activityFeedApi.util.updateQueryData(
            'getPostById',
            { postId: activityFeedItemId, projectId: eventProjectId },
            (draft) => {
              if (draft) {
                if (actionType === 'like') {
                  draft.likesCount += 1;
                } else {
                  draft.likesCount -= 1;
                }
              }
            }
          )
        );
      }
    };

    const likeUnlikeComment = (
      activityFeedItemId: number,
      eventProjectId: number,
      postId: number,
      actionType: 'like' | 'unlike',
      rootCommentId?: number
    ) => {
      dispatch(
        likeUnlikeLocalComment({
          activityFeedItemId,
          postId,
          rootCommentId,
          commentId: activityFeedItemId,
          projectId: eventProjectId,
          increase: actionType === 'like',
          fromSocket: true,
        })
      );

      dispatch(
        activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
          if (draft?.items) {
            if (rootCommentId) {
              const rootIndex = draft.items.findIndex(({ id }) => id === rootCommentId);
              if (rootIndex >= 0 && draft.items[rootIndex]?.replies?.length) {
                const commentIndex = draft.items[rootIndex].replies.findIndex(
                  ({ activityFeedItemId: commentId }) => commentId === activityFeedItemId
                );
                if (commentIndex >= 0) {
                  if (actionType === 'like') {
                    draft.items[rootIndex].replies[commentIndex].likesCount += 1;
                  } else {
                    draft.items[rootIndex].replies[commentIndex].likesCount -= 1;
                  }
                }
              }
            } else {
              const commentIndex = draft.items.findIndex(
                ({ activityFeedItemId: commentId }) => commentId === activityFeedItemId
              );
              if (commentIndex >= 0) {
                if (actionType === 'like') {
                  draft.items[commentIndex].likesCount += 1;
                } else {
                  draft.items[commentIndex].likesCount -= 1;
                }
              }
            }
          }
        })
      );
    };

    const increasePostCommentsCount = (
      data: ISocketComment,
      eventProjectId: number,
      postId?: number
    ) => {
      if (postId) {
        dispatch(
          activityFeedApi.util.updateQueryData(
            'getPostById',
            { projectId: eventProjectId, postId },
            (draft) => {
              if (draft) {
                draft.commentsCount += 1;
              }
            }
          )
        );
      } else {
        dispatch(
          activityFeedApi.util.updateQueryData(
            'getPosts',
            { projectId: eventProjectId },
            (draft) => {
              if (draft?.items) {
                const index = draft.items.findIndex(({ id }) => id === data?.postId);
                if (index >= 0) {
                  draft.items[index].commentsCount += 1;
                }
              }
            }
          )
        );
      }
    };

    const addCommentToPost = (data: ISocketComment, postId: number) => {
      dispatch(
        activityFeedApi.util.updateQueryData('getComments', { postId }, (draft) => {
          if (draft?.items) {
            if (data?.rootCommentId) {
              const index = draft.items.findIndex(({ id }) => id === data?.rootCommentId);
              if (index >= 0) {
                draft.items[index]?.replies?.push({
                  ...data,
                  isView: userMe.id === data?.profile?.id,
                });
              }
            } else {
              draft.items.unshift({
                ...data,
                isView: userMe.id === data?.profile?.id,
                replies: [],
              });
            }
          }
        })
      );
    };

    const addLocalCommentToPost = (data: ISocketComment, isView: boolean) => {
      dispatch(
        addComment({
          ...data,
          isView,
          isLiked: false,
          likesCount: 0,
        })
      );
    };

    const handleSelectionEvent = (data: ISocketSelectionNotification) => {
      if (data.selectionItem) {
        const receivedSelection = data.selectionItem;
        dispatch(
          selectionsApi.util.updateQueryData(
            'getCostCodesWithSelections',
            { projectId },
            (draft) => {
              if (draft?.items) {
                const categoryIndex = draft.items.findIndex(
                  ({ id }) => id === receivedSelection?.costCode?.id
                );

                if (categoryIndex >= 0) {
                  // new item
                  if (data.event === SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_ADDED) {
                    if (receivedSelection.status === SelectionStatusEnum.APPROVED) {
                      const approvedOption = receivedSelection.options.find(
                        ({ isSelected }) => isSelected
                      );
                      if (approvedOption) {
                        draft.items[categoryIndex].approvedSelectionAmountDecimalForm =
                          getTotalSelectionsAmount(
                            draft.items[categoryIndex].approvedSelectionAmountDecimalForm,
                            Number(approvedOption.amount) * approvedOption.quantity,
                            'sum'
                          );
                      }
                    }
                    draft.items[categoryIndex].selections.unshift(receivedSelection);
                    dispatch(selectionsApi.util.invalidateTags(['Selections Summary']));
                  }
                  // approve item
                  if (
                    data.event === SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_APPROVED
                  ) {
                    const selectionIndex = draft.items[categoryIndex].selections?.findIndex(
                      ({ id: sId }) => (sId = receivedSelection.id)
                    );
                    if (selectionIndex >= 0) {
                      draft.items[categoryIndex].selections[selectionIndex] = receivedSelection;

                      const approvedOption = receivedSelection.options.find(
                        ({ isSelected }) => isSelected
                      );

                      if (approvedOption) {
                        draft.items[categoryIndex].approvedSelectionAmountDecimalForm =
                          getTotalSelectionsAmount(
                            draft.items[categoryIndex].approvedSelectionAmountDecimalForm,
                            Number(approvedOption.amount) * approvedOption.quantity,
                            'sum'
                          );
                      }

                      dispatch(selectionsApi.util.invalidateTags(['Selections Summary']));
                    }
                  }
                }
              }
            }
          )
        );
      }
    };

    const messagesListener = (eventData: TActivityFeedEvent) => {
      if (eventData?.projectId && eventData.projectId === projectId) {
        if (eventData?.type === 'NEW_POST') {
          if (eventData?.data?.postUserIds?.includes(userMe.id)) {
            addPost(eventData.data, eventData.projectId);
            addNotification(
              'messages',
              eventData.projectId,
              [eventData.projectId],
              userMe.id !== eventData.data?.data?.profile?.id
            );
          }
        }

        if (eventData?.type === 'NEW_COMMENT') {
          if (eventData?.data?.userIds?.includes(userMe.id)) {
            increasePostCommentsCount(eventData.data, eventData.projectId);

            addNotification(
              'messages',
              eventData.projectId,
              [eventData.projectId],
              userMe.id !== eventData?.data?.profile?.id
            );

            if (
              searchParams.get('postId') &&
              Number(searchParams.get('postId')) === eventData?.data?.postId
            ) {
              const postId = Number(searchParams.get('postId'));

              increasePostCommentsCount(eventData.data, eventData.projectId, postId);

              addCommentToPost(eventData.data, postId);
            } else {
              addLocalCommentToPost(eventData.data, userMe.id === eventData?.data?.profile?.id);
            }
          }
        }

        if (eventData?.type === 'NEW_NOTIFICATION') {
          if (isEventTypeSelection(eventData.data)) {
            if (
              (eventData.data.event ===
                SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_ADDED &&
                userMe.id !== eventData.data.selectionItem.createdByProfileId) ||
              (eventData.data.event ===
                SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_APPROVED &&
                userMe.id !== eventData.data.selectionItem?.approvedByProfile?.id)
            ) {
              const filterCondition =
                eventData.data.event ===
                SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_ADDED
                  ? eventData.data?.selectionItem?.createdByProfileId &&
                    eventData.data.selectionItem.createdByProfileId !== userMe.id
                  : eventData.data?.selectionItem?.approvedByProfile?.id !== userMe.id;

              addNotification(
                'notifications',
                eventData.projectId,
                [eventData.projectId],
                filterCondition
              );

              handleSelectionEvent(eventData.data);
            }
          } else {
            addNotification(
              'notifications',
              eventData.projectId,
              [eventData.projectId],
              userMe.id !== eventData.data?.notification?.profileId
            );
          }
        }

        if (eventData?.type === 'CHANGED_POST') {
          if (eventData?.profileId !== userMe?.id) {
            updatePost(eventData.data, eventData.projectId);
          }
        }

        if (eventData?.type === 'CHANGED_COMMENT') {
          if (eventData?.profileId !== userMe?.id) {
            updateComment(eventData.data);
          }
        }

        if (eventData?.type === 'DELETED_COMMENT') {
          if (eventData?.data?.profileId !== userMe?.id) {
            if (eventData?.data?.activityFeedItemIds?.[0]) {
              const { activityFeedItemId, postId, rootCommentId } =
                eventData.data.activityFeedItemIds[0];
              deleteComment(activityFeedItemId, postId, eventData.projectId, rootCommentId);
            }
          }
        }

        if (eventData?.type === 'DELETED_POST') {
          if (eventData?.data?.profileId !== userMe?.id) {
            if (eventData?.data?.activityFeedItemIds?.[0]) {
              const { activityFeedItemId } = eventData.data.activityFeedItemIds[0];
              deletePost(activityFeedItemId, eventData?.projectId);
            }
          }
        }

        if (eventData?.type === 'LIKED_POST' || eventData.type === 'UNLIKED_POST') {
          if (
            eventData?.data?.profileId !== userMe?.id &&
            eventData?.data?.activityFeedItemId &&
            eventData?.projectId
          ) {
            likeUnlikePost(
              eventData?.data?.activityFeedItemId,
              eventData?.projectId,
              eventData.type === 'LIKED_POST' ? 'like' : 'unlike'
            );
          }
        }

        if (eventData?.type === 'LIKED_COMMENT' || eventData?.type === 'UNLIKED_COMMENT') {
          if (
            eventData?.data?.profileId !== userMe?.id &&
            eventData?.data?.activityFeedItemId &&
            eventData?.projectId
          ) {
            likeUnlikeComment(
              eventData?.data?.activityFeedItemId,
              eventData?.projectId,
              eventData?.data?.postId,
              eventData?.type === 'LIKED_COMMENT' ? 'like' : 'unlike',
              eventData?.data?.rootCommentId
            );
          }
        }
      } else if (
        eventData?.projectId &&
        projectIds?.length &&
        projectIds.includes(eventData.projectId)
      ) {
        switch (eventData.type) {
          case 'NEW_POST':
            addNotification(
              'messages',
              eventData.projectId,
              projectIds,
              userMe.id !== eventData.data?.data?.profile?.id &&
                eventData?.data?.postUserIds?.includes(userMe.id)
            );
            break;
          case 'NEW_COMMENT':
            addNotification(
              'messages',
              eventData.projectId,
              projectIds,
              userMe.id !== eventData?.data?.profile?.id &&
                eventData?.data?.userIds?.includes(userMe.id)
            );
            break;
          case 'NEW_NOTIFICATION':
            if (isEventTypeSelection(eventData.data)) {
              const filterCondition =
                eventData.data.event ===
                SelectionNotificationEventEnum.SELECTION_ITEM_HAS_BEEN_ADDED
                  ? eventData.data?.selectionItem?.createdByProfileId &&
                    eventData.data.selectionItem.createdByProfileId !== userMe.id
                  : eventData.data?.selectionItem?.approvedByProfile?.id !== userMe.id;

              addNotification('notifications', eventData.projectId, projectIds, filterCondition);
            } else {
              addNotification(
                'notifications',
                eventData.projectId,
                projectIds,
                userMe.id !== eventData.data?.notification?.profileId
              );
            }
            break;
          default:
            break;
        }
      }
    };

    const projectCreationListener = (eventData: TActivityFeedEvent) => {
      if (eventData.type === 'projectCreatorJoinToRoom') {
        socket.current.emit(SocketEvents.JOIN_TO_ROOM_EVENT, eventData);
      } else if (eventData.type === 'NEW_NOTIFICATION') {
        if (eventData?.projectId && eventData.projectId === projectId) {
          addNotification('notifications', eventData.projectId, [eventData.projectId]);
        } else if (
          eventData?.projectId &&
          projectIds?.length &&
          projectIds.includes(eventData.projectId)
        ) {
          addNotification('notifications', eventData.projectId, projectIds);
        }
      }
    };

    if (socket.current && userMe?.id) {
      socket.current.on(SocketEvents.SEND_MESSAGE_EVENT, messagesListener);
      socket.current.on(
        `${SocketEvents.ADD_TO_PROJECT_EVENT}/${userMe.id}`,
        projectCreationListener
      );
    }

    return () => {
      if (socket.current && socket.current.connected) {
        socket.current.removeAllListeners();
      }
    };
  }, [projectId, projectIds, searchParams.get('postId'), userMe?.id]);

  return null;
};
